import { action, observable } from 'mobx';
import ProductApi from 'api/ProductApi';
import PaymentApi from 'api/PaymentApi';

class PaymentFormStore {
    @observable product;

    @observable form = {
        isCompanyInvoice: false,
        companyName: '',
        companyCode: '',
        companyAddress: '',
        productId: '',
        count: 1
    }

    @observable errorEnabled;
    @observable errorMessage;
    @observable successEnabled;
    @observable successMessage;

    @observable loading = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    pay() {
        this.setLoading(true);
        postPaymentPromise(this.form, this.rootStore.user.token)
            .then((url) => {
                window.location.replace(url);
            })
            .catch((message) => {
                this.enableErrorAlert(message);
                this.setLoading(false);
            })
    }

    @action
    getProduct(id) {
        this.setLoading(true);
        getProductPromise(id, this.rootStore.user.token)
            .then((product) => {
                this.setProduct(product);
                this.setFormValue('productId', Number(id));
                this.checkForCompany();
                this.setLoading(false);
            })
            .catch((message) => {
                this.enableErrorAlert(message)
                this.setLoading(false);
            });
    }

    @action.bound
    checkForCompany() {
        const { user } = this.rootStore;

        if (user.isCompany) {
            this.setFormValue('isCompanyInvoice', true);
            this.setFormValue('companyName', user.companyName ? user.companyName : '');
            this.setFormValue('companyCode', user.companyCode ? user.companyCode : '');
            this.setFormValue('companyAddress', user.companyAddress ? user.companyAddress : '');
        }
    }

    @action
    setProduct(value) {
        this.product = value;
    }

    @action
    setLoading(value) {
        this.loading = value;
    }

    @action
    enableSuccessAlert(message) {
        this.successMessage = message;
        this.successEnabled = true;
    }

    @action
    enableErrorAlert(message) {
        this.errorMessage = message;
        this.errorEnabled = true;
    }

    @action
    setFormValue(fieldName, value) {
        this.form[fieldName] = value;
    }
}

const getProductPromise = (id, jwt) => {
    return new Promise((resolve, reject) => {
        ProductApi.getProduct(id, jwt)
            .then(({ data }) => resolve(data))
            .catch((res) => {
                if (res.response && res.response.data && res.response.data.message) {
                    reject(res.response.data.message)
                } else {
                    reject('COMMON.SERVER_ERROR')
                }
            });
    })
}

const postPaymentPromise = (form, jwt) => {
    return new Promise((resolve, reject) => {
        PaymentApi.postPayment(form, jwt)
            .then(({ data }) => resolve(data))
            .catch((res) => {
                reject('COMMON.SERVER_ERROR')
            });
    })
}

export default PaymentFormStore;
