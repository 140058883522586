import React, { Component, Fragment } from 'react';
import { Alert, Button, Container, Form, FormControl, FormGroup, FormLabel, Modal, Spinner } from 'react-bootstrap';
import Req from 'app/components/Req';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import FormValidators from 'app/components/validators/FormValidators';

@translate()
@inject('store')
@observer
class ProductAddModal extends Component {
    componentDidMount() {
        this.props.store.productForm.resetForm();
    }

    submitForm(e) {
        e.preventDefault();
        this.props.store.productForm.upload();
    }

    valueChange(fieldName, value) {
        this.props.store.productForm.setFormValue(fieldName, value);
    }

    isFormValid() {
        const { title, price, description, subscriptionPeriod } = this.props.store.productForm.form;

        const { empty } = new FormValidators();

        return !empty(title) && !empty(price) && (Number(price) > 0) && Number.isInteger(Number(price)) &&
            !empty(description) && !empty(subscriptionPeriod) && Number.isInteger(Number(subscriptionPeriod));
    }

    render() {
        const { t } = this.props;
        const {
            form: { title, price, description, subscriptionPeriod, isCreditSubscription, isOnlineSubscription,
                isSubscriptionFull, isCountable },
            loading, errorEnabled, errorMessage, successEnabled, successMessage, show
        } = this.props.store.productForm;
        const valueChange = fieldName => e => this.valueChange(fieldName, e.target.value)

        return (
            <Fragment>
                <Container>
                    <Modal size="md" show={show} onHide={() => this.props.store.productForm.toggleModal()}>
                        <Form onSubmit={(e) => this.submitForm(e)}>
                            <Modal.Header>
                                <span className="font-weight-bolder">{t('COMMON.UPLOAD_NEW_PRODUCT')}</span>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group>
                                    <span className="tab-email-change">{t('COMMON.UPLOAD_NEW_PRODUCT')}</span>
                                </Form.Group>

                                <FormGroup>
                                    <FormLabel>{t('COMMON.TITLE')}<Req /></FormLabel>
                                    <FormControl type="text" value={title} onChange={valueChange('title')} />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>{t('COMMON.PRICE')}<Req /></FormLabel>
                                    <FormControl type="number" value={price} onChange={valueChange('price')} />
                                </FormGroup>

                                <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input"
                                               id="isSubscriptionFull"
                                               checked={isSubscriptionFull}
                                               disabled={(isCreditSubscription || isOnlineSubscription)}
                                               onChange={() =>
                                                   this.valueChange('isSubscriptionFull', !isSubscriptionFull)}
                                        />
                                        <label className="custom-control-label pointer" htmlFor="isSubscriptionFull">
                                            {t('COMMON.SUBSCRIPTION_IS_FULL')}
                                        </label>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input"
                                               id="isOnlineSubscription"
                                               checked={isOnlineSubscription}
                                               disabled={(isCreditSubscription || isSubscriptionFull)}
                                               onChange={() =>
                                                   this.valueChange('isOnlineSubscription', !isOnlineSubscription)}
                                        />
                                        <label className="custom-control-label pointer" htmlFor="isOnlineSubscription">
                                            {t('COMMON.IS_SUBSCRIPTION_ONLINE')}
                                        </label>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input"
                                               id="isCreditSubscription"
                                               checked={isCreditSubscription}
                                               disabled={(isOnlineSubscription || isSubscriptionFull)}
                                               onChange={() =>
                                                   this.valueChange('isCreditSubscription', !isCreditSubscription)}
                                        />
                                        <label className="custom-control-label pointer" htmlFor="isCreditSubscription">
                                            {t('COMMON.SUBSCRIPTION_IS_CREDIT_BASED')}
                                        </label>
                                    </div>
                                </div>

                                {isCreditSubscription &&
                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input"
                                                   id="isCountable"
                                                   checked={isCountable}
                                                   onChange={() =>
                                                       this.valueChange('isCountable', !isCountable)}
                                            />
                                            <label className="custom-control-label pointer"
                                                   htmlFor="isCountable">
                                                {t('COMMON.SUBSCRIPTION_IS_COUNTABLE')}
                                            </label>
                                        </div>
                                    </div>
                                }

                                <FormGroup>
                                    <FormLabel>
                                        {t(isCreditSubscription ?
                                            'COMMON.SUBSCRIPTION_CREDIT' : 'COMMON.SUBSCRIPTION_PERIOD_DAYS')}
                                        <Req />
                                    </FormLabel>
                                    <FormControl type="number" value={subscriptionPeriod}
                                                 onChange={valueChange('subscriptionPeriod')} />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>{t('COMMON.DESCRIPTION')}<Req /></FormLabel>
                                    <FormControl as="textarea" rows={3} value={description}
                                                 onChange={valueChange('description')} />
                                </FormGroup>

                                {successEnabled &&
                                <Alert variant="success" className="text-center">{t(successMessage)}</Alert>
                                }

                                {errorEnabled &&
                                <Alert variant="warning" className="text-center">{t(errorMessage)}</Alert>
                                }

                                <div className="form-group">
                                    <Button variant="primary" className="btn-block" type="submit"
                                            disabled={loading || !this.isFormValid()}
                                            onClick={() => {}}>
                                        {t('COMMON.ADD')}
                                        {loading &&
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="custom"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        }
                                    </Button>
                                </div>
                            </Modal.Body>
                        </Form>
                    </Modal>
                </Container>
            </Fragment>
        );
    }
}

export default ProductAddModal;
